export enum SpecializedEquipmentType {
  A = 'ALL_TYPES',
  // Flatbed Types
  F = 'FLATBED_ANY_TYPE_F',
  BT = 'B_TRAIN',
  DF = 'DROPDECK_FLAT',
  DD = 'DBL_DROP_FLAT',
  FDD = 'DBL_DROP_FLAT',
  FD = 'FLATBED_OR_STEPDECK_F',
  FH = 'FLAT_HOTSHOT',
  HS = 'FLAT_HOTSHOT',
  FO = 'FLAT_ONLY',
  PO = 'POWER_ONLY',
  FSD = 'FLATBED_WITH_SIDES',
  FS = 'FLATBED_WITH_SIDES',
  FST = 'STRETCH_TRAILER_F',
  ST = 'STRETCH_TRAILER_F',
  RGN = 'REMOVABLE_GOOSENECK_F',
  FA = 'FLAT_AIR',
  MX = 'MAXI',
  SD = 'STEP_DECK_F',
  SF = 'SPECIALIZED_FLATS_F',
  TP = 'TANDEM_PIPE',
  TM = 'TANDEM_MACHINERY',
  SSD = 'STRETCH_STEP_DECK',
  SDD = 'STRETCH_DOUBLE_DROP',
  SDC = 'STEP_DECK_CURTAIN',
  DDC = 'DOUBLE_DROP_CURTAIN_F',
  CN = 'CONESTOGA',
  CV = 'CURTAIN_VAN_F',

  // Bulk Types
  B = 'BULK',
  BHOP = 'HOPPER_B',
  BFGD = 'FOOD_GRADE_DRY_BULK_B',
  BEND = 'END_DUMP_B',
  BDPT = 'DRY_PNEUMATIC_TRAILER_B',
  BVT = 'VACUUM_TRAILER_B',
  BLSS = 'LIQUID_STAINLESS_STEEL_TRAILER_B',
  BLRL = 'LIQUID_RUBBER_LINED_TRAILER_B',
  BLFRPT = 'LIQUID_FRP_TANK_TRAILER_B',
  BLFGT = 'LIQUID_FOOD_GRADE_TANK_TRAILER_B',
  BLAT = 'LIQUID_ALUMINUM_TANK_TRAILER_B',
  BISO = 'ISO_TANK',
  BST = 'SAND_TRAILER_B',
}
