import React from 'react';

import { SpecializedEquipmentType } from 'shared/enums/specialized-equipment-type.enum';
import { EquipmentTypeList, getEquipmentTypeListString } from 'shared/components/loads/equipment-types-list.component';
import { Translation } from 'shared/components/translation/translation.component';
import { ComponentConnectorFactory } from 'store/component-connector';
import { select } from 'store/selectors/state.selectors';

/**
 * Turns `RV` into 'Reefer, Van', for example.
 * @returns {string}
 */
export const getExtendedEquipmentTypeList = (
  specializedCode: SpecializedEquipmentType | string,
  equipmentCode: string,
  extendedEquipmentTypes: ReferenceDataJSON[]
) => {
  const type = SpecializedEquipmentType[specializedCode];
  const exType = extendedEquipmentTypes.find(extype => extype.code === specializedCode);

  if (type) {
    return <Translation resource={type}/>;
  }
  if (exType) {
    return <React.Fragment>{exType.description}</React.Fragment>;
  }

  return (
    <React.Fragment>
      <EquipmentTypeList equipmentCode={equipmentCode}/> ({specializedCode})
    </React.Fragment>
  );
};
/**
 * Turns `RV` into 'Reefer, Van', for example.
 * @returns {string}
 */
export const getExtendedEquipmentTypeListString = (
  isEuropean: boolean,
  specializedCode: SpecializedEquipmentType | string,
  equipmentCode: string,
  extendedEquipmentTypes: ReferenceDataJSON[],
  resources: Resources
): string => {
  const type = SpecializedEquipmentType[specializedCode];
  const exType = extendedEquipmentTypes.find(extype => extype.code === specializedCode);

  if (type) {
    return resources[type];
  }
  if (exType) {
    return exType.description;
  }

  return getEquipmentTypeListString(isEuropean, equipmentCode, resources) + ' (' + specializedCode + ')';
};

export interface OwnProps {
  equipmentCode: string;
  specializedCode: SpecializedEquipmentType | string;
}

interface ConnectStateProps {
  extendedEquipmentTypes: ReferenceDataJSON[];
}

type Props = OwnProps & ConnectStateProps;

export const SpecializedEquipmentTypeListComponent: React.FunctionComponent<Props> = ({specializedCode, equipmentCode, extendedEquipmentTypes}) =>
  <span>
    {getExtendedEquipmentTypeList(specializedCode, equipmentCode, extendedEquipmentTypes)}
  </span>;

export const SpecializedEquipmentTypeList = ComponentConnectorFactory<OwnProps, ConnectStateProps>()
  .combineStateSelectors(select.refData.extendedEquipmentTypes)
  .connect(SpecializedEquipmentTypeListComponent);
